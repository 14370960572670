import PropTypes, { func } from "prop-types";
import React, { forwardRef, useState, useEffect } from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import cx from "classnames";
import Arrow from "../../images/ArrowRight.svg";

const CampaignCause = React.forwardRef(
  ({ image, logo, heading, body, handleCauseOffset, handleRefreshST }, ref) => {
    const [expanded, setExpanded] = useState(false);
    const [scrollHeight, setScrollHeight] = useState();

    const toggleExpand = () => {
      if (ref.current.style.maxHeight !== "400px") {
        ref.current.classList.add("gradient-overlay-short");
        handleCauseOffset();
      } else {
        ref.current.classList.remove("gradient-overlay-short");
        ref.current.style.maxHeight = "2000px";
        setTimeout(() => {
          handleRefreshST();
        }, 200);
      }
    };

    useEffect(() => {
      setScrollHeight(ref.current.scrollHeight);
    });

    return (
      <section
        ref={ref}
        className={cx(
          "relative flex flex-col text-center text-white bg-dark-purple transition-all duration-300 overflow-hidden hover:cursor-pointer gradient-overlay-short"
        )}
        onClick={toggleExpand}
      >
        {scrollHeight && (
          <GatsbyImage
            image={image.childImageSharp.gatsbyImageData}
            alt={image.name}
            className="!absolute inset-0 opacity-50"
            imgClassName="absolute inset-0"
            style={{ height: "" + scrollHeight + "px" }}
          />
        )}
        <div className="relative w-auto px-6 py-1 mx-auto uppercase bg-orange c-overline">
          The Cause
        </div>

        <div className="relative w-full  pb-[100px]">
          <div className="z-20 w-full py-6 mx-auto md:w-2/3 container-wide">
            <GatsbyImage
              image={logo.childImageSharp.gatsbyImageData}
              objectFit="contain"
              className="w-[100px] h-auto mx-auto my-6"
              alt={logo.name}
            />
            <h2 className="mb-4 c-h3">{heading}</h2>
            <div className="rich-text" dangerouslySetInnerHTML={body}></div>
          </div>
        </div>
      </section>
    );
  }
);

CampaignCause.propTypes = {
  className: PropTypes.string,
};

export default CampaignCause;
