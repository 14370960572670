import PropTypes from "prop-types";
import React, { forwardRef } from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import cx from "classnames";

const CampaignRelated = React.forwardRef(({ campaign }, ref) => {
  const { campaignImages, campaigns } = useStaticQuery(
    graphql`
      query {
        campaigns: allMarkdownRemark(
          filter: { frontmatter: { type: { eq: "campaign-page" } } }
        ) {
          edges {
            node {
              frontmatter {
                slug
                title
                hero {
                  image
                  objectPosition
                }
                thumbnail {
                  alertMessage
                  heading
                  subheading
                  button {
                    text
                    url
                  }
                }
              }
            }
          }
        }
        campaignImages: allFile(
          filter: { relativeDirectory: { eq: "campaign-images" } }
        ) {
          nodes {
            name
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH, quality: 90)
            }
          }
        }
      }
    `
  );

  const relatedCampaigns = campaigns.edges
    .filter((camp) => camp.node.frontmatter.slug !== campaign)
    .slice(0, 3);

  return (
    <section
      ref={ref}
      className="relative w-full text-center text-white bg-dark-purple"
    >
      <div className="grid grid-cols-1 gap-4 pt-12 pb-2 sm:grid-cols-2 lg:grid-cols-3 container-wide">
        {relatedCampaigns.map((campaign, i) => {
          const camp = campaign.node.frontmatter;
          const image = campaignImages.nodes.find(
            (img) => img.name === camp.hero.image
          );
          return (
            <div
              key={"related-" + i}
              className={cx(
                "relative flex-col justify-end col-span-1 text-left aspect-3/4",
                {
                  "hidden lg:flex": i === 2,
                  flex: i !== 2,
                }
              )}
              data-sal="slide-right"
              data-sal-duration="500"
              data-sal-delay={i * 100}
              data-sal-easing="ease"
            >
              <GatsbyImage
                image={image.childImageSharp.gatsbyImageData}
                className="!absolute inset-0 gradient-overlay"
                imgClassName="absolute inset-0"
                alt={image.name}
              />
              <div className="relative flex flex-col items-start justify-end p-6 text-shadow">
                <h2 className="c-overline text-orange">{camp.title}</h2>
                <h3 className="c-h5">{camp.thumbnail.heading}</h3>
                <p>{camp.thumbnail.subheading}</p>
                <Link
                  to={camp.thumbnail.button.url}
                  className="mt-6 c-button text-shadow-none"
                >
                  Learn More
                </Link>
              </div>
            </div>
          );
        })}
      </div>
    </section>
  );
});

CampaignRelated.propTypes = {
  className: PropTypes.string,
};

export default CampaignRelated;
