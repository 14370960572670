import React, { useContext, useRef, useEffect, useState } from "react";
import SEO from "../../components/seo";

// DATA
import { graphql } from "gatsby";
import CampaignRelated from "../../components/campaigns/related-block";

// Components
import { StateContext } from "../../state/StateProvider";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

// Events and Awards
import { Toaster } from 'react-hot-toast'
import Toast from "../../components/profile/toast"

// Sections
import Layout from "../../components/layout";
import CampaignHero from "../../components/campaigns/hero";
import CampaignCause from "../../components/campaigns/cause-block";
import CompletionBlock from "../../components/campaigns/completion-block";
import UsoSponsorBlock from "../../components/campaigns/uso-sponsor-block";
import OneHeartForestSponsorBlock from "../../components/campaigns/one-heart-forest-sponsor-block";

export default function CampaignDetail({ data }) {
  // console.log("data:", data);
  const { markdownRemark, campaignImages, campaignLogo } = data; // data.markdownRemark holds your post data
  const { frontmatter, html } = markdownRemark;
  const { slug, seoTitle, hero, cause, completion, campaignActive } =
    frontmatter;

  const { isDark, setTheme } = useContext(StateContext);
  useEffect(() => {
    setTheme(true);
  }, []);

  const [progBar, setProgBar] = useState(0);
  const [progCompleted, setProgCompleted] = useState(false);
  const [pinIsActive, setPinIsActive] = useState(false);

  const heroRef = useRef();
  const causeRef = useRef();
  const wrapperRef = useRef();
  const sponsorRef = useRef();

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      gsap.registerPlugin(ScrollTrigger);

      ScrollTrigger.create({
        trigger: wrapperRef.current,
        start: "top 100px",
        end: "bottom bottom",
        pin: heroRef.current,
        pinSpacing: false,
        invalidateOnRefresh: true,
        onUpdate: (self) => {
          checkPinIsActive(self.isActive);
        },
        // markers: true,
      });

      ScrollTrigger.create({
        trigger: sponsorRef.current,
        start: "top top+=100px",
        end: "bottom bottom",
        pinSpacing: false,
        invalidateOnRefresh: true,
        onUpdate: (self) => {
          
          checkProgress(self.progress);
        },
        // markers: true,
      });

      handleCauseOffset();
    }
    return () => {
      isMounted = false;
    };
  }, []);

  const checkPinIsActive = (state) => {
    setPinIsActive(state);
  };

  const checkProgress = (progress) => {
    setProgBar(progress);
    if (progress === 1) {
      setProgCompleted(true);
      setAchievement();
    }
  };

  const setAchievement = (progress) => {
    if (!completion.achievement) {
      return;
    }

    console.log("CALL EVENT")
    
    fetch(`${process.env.GATSBY_API_HOST}/v1/event`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        'Origin': `${process.env.GATSBY_API_HOST}`,
      },
      body: JSON.stringify({event_id: completion.achievement})
    })
      .then(res => {
        return res.json()
      })
      .then(result => {
        console.log("AWARD THINGY", result)
        if (result.new_award) { 
           Toast(result.image, result.message)
         }
      })
      .catch(rejected => {
        console.log("REJECTED", rejected)
      });
  }

  const handleCauseOffset = () => {
    causeRef.current.style.maxHeight = "400px";
    setTimeout(() => {
      ScrollTrigger.refresh();
    }, 300);
  };

  return !slug ? null : (
    <Layout>
      <SEO keywords={[`sparkrise`, `campaigns`]} title={seoTitle} />
      <div className="bg-dark-purple">
        <div className="mx-full relative z-10 flex flex-col justify-end py-8 container-wide text-shadow">
        <CampaignHero
          ref={heroRef}
          progBar={progBar}
          progCompleted={progCompleted}
          pinIsActive={pinIsActive}
          image={campaignImages.nodes.find((img) => img.name === hero.image)}
          organizations={hero.organizations}
          heading={hero.heading}
        />
        </div>
        <div ref={wrapperRef}>
          <CampaignCause
            ref={causeRef}
            handleCauseOffset={handleCauseOffset}
            handleRefreshST={ScrollTrigger.refresh}
            image={campaignImages.nodes.find(
              (img) => img.name === cause.backgroundImage
            )}
            logo={campaignImages.nodes.find((img) => img.name === cause.logo)}
            heading={cause.heading}
            body={{
              __html: cause.body,
            }}
          />
          {slug === "top-gun-celebration-2021" && (
            <UsoSponsorBlock ref={sponsorRef} campaignImages={campaignImages} />
          )}
          {slug === "sheets-and-giggles-2022" && (
            <OneHeartForestSponsorBlock
              ref={sponsorRef}
              campaignImages={campaignImages}
            />
          )}
        </div>
        <CompletionBlock
          image={campaignImages.nodes.find(
            (img) => img.name === completion.image
          )}
          logoImage={campaignImages.nodes.find(
            (img) => img.name === completion.logo
          )}
          heading={completion.heading}
          subheading={completion.subheading}
          sparkText={completion.sparkText}
          donationUrl={completion.donationUrl}
          campaignActive={campaignActive}
          achievement={completion.achievement}
        />
        <CampaignRelated campaign={slug} />
        <Toaster
          position="top-center"
          reverseOrder={false}
        />
      </div>
    </Layout>
  );
}

export const pageQuery = graphql`
  query ($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        slug
        type
        title
        completion {
          donationUrl
          heading
          image
          logo
          sparkText
          subheading
          achievement
        }
        cause {
          backgroundImage
          body
          heading
          logo
        }
        campaignActive
        seoTitle
        hero {
          heading
          image
          objectPosition
          organizations
        }
      }
    }
    campaignImages: allFile(
      filter: { relativeDirectory: { eq: "campaign-images" } }
    ) {
      nodes {
        name
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 90)
        }
      }
    }
    campaignLogo: file(name: { eq: "image-23" }) {
      name
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, quality: 90)
      }
    }
  }
`;
