import PropTypes from "prop-types";
import React, { forwardRef } from "react";
import { GatsbyImage } from "gatsby-plugin-image";

import CompletionLogo from "../../images/completion-logo.svg";
const CompletionBlock = React.forwardRef(
  (
    {
      image,
      logoImage,
      heading,
      subheading,
      sparkText,
      donationUrl,
      campaignActive,
      achievement,
    },
    ref
  ) => {

    const onScroll = (e) => { console.log(e); console.log(achievement)}
    return (
      <section id="" className="relative">
        <GatsbyImage
          image={image.childImageSharp.gatsbyImageData}
          alt={image.name}
          className="!absolute inset-0"
          imgClassName="absolute inset-0"
        />
        {campaignActive ? (
          <div className="relative z-10 flex flex-col items-center justify-center w-full mx-auto text-center text-white py-28 md:flex-row container-narrow">
            <div className="w-full md:w-2/3">
              <h2
                className="c-h2"
                data-sal="slide-up"
                data-sal-duration="600"
                data-sal-easing="ease"
              >
                {heading}
              </h2>
              <p>{subheading}</p>
              <p className="mt-4 uppercase c-h6">create more sparks</p>
              <p>Share your unique URL to multiply your impact</p>
            </div>
            <div className="w-full md:w-1/3">
              {logoImage && (
                <GatsbyImage
                  image={logoImage.childImageSharp.gatsbyImageData}
                  objectFit="contain"
                  className="w-[200px] h-auto mx-auto my-6"
                  alt={logoImage.name}
                />
              )}
              <p className="text-5xl font-bold">8,045</p>
              <p>{sparkText}</p>
              <div className="mt-4" onScroll={onScroll}>
                <a href={donationUrl} className="c-button3">
                  Donate To This Cause
                </a>
              </div>
            </div>
          </div>
        ) : (
          <div className="relative z-10 flex flex-col items-center justify-center w-full mx-auto text-center text-white py-28 container-narrow">
            <CompletionLogo width={125} height={125} />
            <p className="text-5xl font-bold">10,450</p>
            <p>{sparkText}</p>
            <h2
              className="c-h2"
              data-sal="slide-up"
              data-sal-duration="600"
              data-sal-easing="ease"
            >
              This Campaign has ended.
            </h2>
            <p>The campaign is over, but you can still donate to the cause!</p>
            <div className="mt-4">
              <a href={donationUrl} className="c-button3">
                Donate To This Cause
              </a>
            </div>
          </div>
        )}
      </section>
    );
  }
);

CompletionBlock.propTypes = {
  className: PropTypes.string,
};

export default CompletionBlock;
