import PropTypes from "prop-types";
import React, { forwardRef } from "react";
import { GatsbyImage } from "gatsby-plugin-image";

import YouTube from "@u-wave/react-youtube";
import CompletionLogo from "../../images/completion-logo.svg";
const OneHeartForestSponsorBlock = React.forwardRef(
  ({ campaignImages }, ref) => {
    const image01 = campaignImages.nodes.find(
      (image) => image.name === "Morgan-and-Pete"
    );
    const sponsorLogos = ["Sheets-Giggles-Logo"];

    return (
      <section
        ref={ref}
        className="relative w-full text-center text-white bg-purple"
      >
        <div className="flex flex-col items-center justify-start container-wide">
          <div className="relative px-6 py-1 text-center uppercase bg-orange c-overline">
            The Sponsor
          </div>
          <div className="relative w-full py-12 sm:w-2/3">
            <p>This Spark made possible by</p>
            <ul className="flex flex-col items-center justify-center px-12 space-y-4 sm:space-y-0 sm:space-x-8 md:flex-row">
              {sponsorLogos.map((logo, i) => {
                const logoImage = campaignImages.nodes.find(
                  (img) => img.name === logo
                );
                return (
                  <li
                    className="w-[300px]"
                    data-sal="fade"
                    data-sal-duration="600"
                    data-sal-delay={i * 100}
                    data-sal-easing="ease"
                    key={"logo-" + i}
                  >
                    <GatsbyImage
                      key={logo + "-" + i}
                      image={logoImage.childImageSharp.gatsbyImageData}
                      width="300"
                      height="75"
                      className="mx-auto mix-blend-hard-light"
                      alt={logoImage.name}
                    />
                  </li>
                );
              })}
            </ul>
            <div className="relative mt-6 aspect-4/3">
              <GatsbyImage
                image={image01.childImageSharp.gatsbyImageData}
                className="absolute inset-0"
                imgClassName="absolute inset-0"
                alt={image01.name}
              />
            </div>
            <div className="relative mt-10 aspect-video">
              <YouTube video={"soBbJrxjixc"} width={"100%"} height={"100%"} />
            </div>
            <div className="mt-10 text-left sm:text-center">
              <h3
                className="uppercase c-h5"
                data-sal="fade"
                data-sal-duration="600"
                data-sal-delay=""
                data-sal-easing="ease"
              >
                about sheets & giggles
              </h3>
              <p
                className="mb-6"
                data-sal="fade"
                data-sal-duration="600"
                data-sal-delay=""
                data-sal-easing="ease"
              >
                We make sustainable bedding from Eucalyptus trees—it’s kind of
                our whole deal. Sheets & Giggle’s lyocell fabric is made out of
                high-quality eucalyptus wood pulp, a sustainable and renewable
                natural resource. We don’t harvest wood from ancient or
                endangered forests. Instead, our eucalyptus trees are grown on
                sustainably managed and biodiverse farms. Plus, for every tree
                harvested, two more are planted.
              </p>
              <div
                data-sal="slide-up"
                data-sal-duration="600"
                data-sal-delay=""
                data-sal-easing="ease"
              >
                <a
                  href="https://sheetsandgiggles.com"
                  className="px-0 underline c-button2 hover:no-underline"
                >
                  Get holiday deals now
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
);

export default OneHeartForestSponsorBlock;
