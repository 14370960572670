import PropTypes from "prop-types";
import React, { forwardRef } from "react";
import { GatsbyImage } from "gatsby-plugin-image";

import CompletionLogo from "../../images/completion-logo.svg";

const UsoSponsorBlock = React.forwardRef(({ campaignImages }, ref) => {
  const image01 = campaignImages.nodes.find(
    (image) => image.name === "top-gun-screen"
  );
  const qrCode = campaignImages.nodes.find(
    (image) => image.name === "top-gun-qr"
  );

  const sponsorLogos = ["dakkota-logo", "display-logo", "wahoo-logo"];

  return (
    <section
      ref={ref}
      className="relative w-full text-center text-white bg-purple"
    >
      <div className="flex flex-col items-center justify-start container-wide">
        <div className="relative px-6 py-1 text-center uppercase bg-orange c-overline">
          The Sponsor
        </div>
        <div className="relative w-full py-12 sm:w-2/3">
          <p className="mb-4">This Spark made possible by</p>
          <ul className="flex flex-col items-center justify-center px-12 space-y-4 sm:space-y-0 sm:space-x-8 md:flex-row">
            {sponsorLogos.map((logo, i) => {
              const logoImage = campaignImages.nodes.find(
                (img) => img.name === logo
              );
              return (
                <li
                  className="w-[300px]"
                  data-sal="fade"
                  data-sal-duration="600"
                  data-sal-delay={i * 100}
                  data-sal-easing="ease"
                  key={"logo-" + i}
                >
                  <GatsbyImage
                    key={logo + "-" + i}
                    image={logoImage.childImageSharp.gatsbyImageData}
                    width="300"
                    height="75"
                    objectFit="contain"
                    className="mx-auto mix-blend-hard-light"
                    alt={logoImage.name}
                  />
                </li>
              );
            })}
          </ul>
          <p
            className="mt-2 c-h5"
            data-sal="slide-up"
            data-sal-duration="600"
            data-sal-delay="200"
            data-sal-easing="ease"
          >
            Please visit our sponsors. Each click or registration makes a
            donation!
          </p>
          <div className="relative mt-6 aspect-4/3">
            <GatsbyImage
              image={image01.childImageSharp.gatsbyImageData}
              className="absolute inset-0"
              objectFit="contain"
              imgClassName="absolute inset-0 w-full"
              alt={image01.name}
            />
          </div>
          <div className="rounded-xl sm:rounded-full c-h5 bg-light-purple">
            <div
              className="w-full px-4 py-2 mx-auto md:w-3/4"
              data-sal="slide-up"
              data-sal-duration="600"
              data-sal-delay="200"
              data-sal-easing="ease"
            >
              Live Online Streaming Event Saturday September 25th, 5pm PT / 8pm
              ET
            </div>
          </div>
          <div className="grid grid-cols-1 mt-6 text-left sm:grid-cols-2 gap-x-4 gap-y-8">
            <div
              className="grid grid-cols-12 col-span-full"
              data-sal="fade"
              data-sal-duration="600"
              data-sal-delay="200"
              data-sal-easing="ease"
            >
              <h5 className="col-span-full c-h5">VAL’S POTLUCK MOVIE NIGHT</h5>
              <p className="pr-8 mb-2 col-span-full sm:col-span-10">
                Join our once-in-a-lifetime live-streaming event! The evening
                begins with Val’s Potluck Movie Night. It’s free to attend, but
                please give what you can by clicking on the donate button during
                the event. Proceeds will benefit the USO and Val Kilmer’s
                TwainMania Foundation. Then click on over to the Top Gun
                Celebration, Both parties will be joined by cast members,
                celebrities, musicians, and military elites from bases across
                the US. Let's party together, to honor our military families and
                celebrate one of the greatest action movies of all time! *
                Exclusive NFTs and the opportunity to interact with the
                panelists.
              </p>
              <div className="w-40 h-40 p-1 bg-white col-span-full sm:col-span-2">
                <GatsbyImage
                  image={qrCode.childImageSharp.gatsbyImageData}
                  alt={qrCode.name}
                />
              </div>
            </div>
            <div
              className="col-span-1"
              data-sal="fade"
              data-sal-duration="600"
              data-sal-delay="200"
              data-sal-easing="ease"
            >
              <h5 className="mb-4 c-h5">
                Agenda #1 Val's Potluck Movie Night (Pre-screening Party)
              </h5>
              <p>5:00pm PT / 8:00pm ET</p>
              <p className="mb-4 ">
                The party will be live-streamed from Val Kilmer’s HelMel Studio
                in Los Angeles. Our show includes some of today's top
                celebrities, eclectic artists and musicians, here to entertain
                you while raising funds and having fun.
              </p>
              <p>5:35pm PT / 8:35pm ET</p>
              <p className="mb-4 ">
                Now it's time to hop on over to the movie portion of the night.
                Tonight's movie is Top Gun – where there will be military
                guests, celebrity surprises, musical artists, and more fun to be
                had... Purchase your tickets in advance, or in the moment.
              </p>
              <h5 className="mb-4 c-h5">
                Help support the USO and TwainMania Foundation
              </h5>
              <a href="https://www.sparkrise.com/go/topgun-pledge" className="c-button">
                Donate Now
              </a>
            </div>
            <div
              className="col-span-1"
              data-sal="fade"
              data-sal-duration="600"
              data-sal-delay="200"
              data-sal-easing="ease"
            >
              <h5 className="mb-4 c-h5">
                Agenda #2 Top Gun Celebration (Interactive Movie Screening)
              </h5>
              <p>5:30pm PT / 8:30pm ET</p>
              <p className="mb-4 ">
                Dinner and a Movie: The movie portion of the night opens with
                our host checking in with viewing parties from across the nation
                and interacting with some of our onstage guests, including Val
                Kilmer (Iceman – in chat), Rick Rossovich (Slider) and Barry
                Tubb (Wolfman), as well as some of our high ranking military
                guests.
              </p>
              <p>5:45pm PT / 8:45pm ET</p>
              <p className="mb-4 ">
                The movie begins – Stay tuned… because you never know who might
                show up! Don’t be surprised when you see some special celebrity
                pop-ins and some once-in-a-lifetime performances!
              </p>
              <p>7:15pm PT / 10:15pm ET</p>
              <p className="mb-4 ">
                The movie ends, and our host wraps-up with more special guests
                and announcements about upcoming events.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
});

export default UsoSponsorBlock;
