import React from "react";

// Events and Awards
import toast from 'react-hot-toast'


const Toast = (image, text) => {
console.log("TOAST TIME", image, text)

  toast.custom((t) => (
    <div
    className={`${
      t.visible ? 'animate-enter' : 'animate-leave'
    } max-w-md w-full bg-light-purple shadow-lg rounded-lg pointer-events-auto flex ring-1 ring-black ring-opacity-5`}
  >
    <div className="flex-1 w-0 p-4">
      <div className="flex items-start">
        <div className="flex-shrink-0 pt-0.5">
          <img
            className="h-10 w-10 rounded-full"
            src="https://www.sparkrise.com/android-chrome-512x512.png"
            alt=""
            style={{width : '120px', height : '120px'}}
          />
        </div>
        <div className="ml-3 h-24 text-white">
          <p className="align-middle">
            {text}
          </p>
        </div>
      </div>
    </div>
    <div className="flex border-l border-gray-200">
      <button
        onClick={() => toast.dismiss(t.id)}
        className="w-full border border-transparent rounded-none rounded-r-lg p-4 flex items-center justify-center text-sm font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500"
      >
        Close
      </button>
    </div>
  </div>
  ))
}
// const Toast = ({ image, value, description }) => {
//   let finalImage = <Carbon className="hidden md:inline-block"/>
//   switch (image) {
//     case 'trees':
//       finalImage = <Trees className="hidden md:inline-block"/>
//   }

//   return (
//     <Toaster
//       position="top-center"
//       reverseOrder={false}
//     />
//   );
// };

export default Toast;









