import PropTypes from "prop-types";
import React, { forwardRef } from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import cx from "classnames";

import Arrow from "../../images/ArrowRight.svg";
import { Context } from "react-responsive";

const CampaignHero = React.forwardRef(
  (
    { image, organizations, heading, progBar, progCompleted, pinIsActive },
    ref
  ) => {
    return (
      <section ref={ref} className="relative z-10">
        <div className="relative flex flex-col justify-end w-full max-h-screen bg-black aspect-1/2 sm:aspect-5/3">
          <GatsbyImage
            image={image.childImageSharp.gatsbyImageData}
            alt={image.name}
            className="!absolute inset-0 gradient-overlay"
            imgClassName="absolute inset-0"
          />
          <div className="z-10 flex flex-col items-center justify-end pt-12 container-wide text-shadow">
            <div
              className="w-full text-center text-white md:w-3/4"
              data-sal="fade"
              data-sal-duration="600"
              data-sal-delay="200"
              data-sal-easing="ease"
            >
              <p className="uppercase c-h6 text-orange">
                {organizations.map((org, i) => {
                  return (
                    <span key={`org-${i}`}>
                      {org}{" "}
                      <span className="text-white">
                        {i !== organizations.length - 1 && "+ "}
                      </span>
                    </span>
                  );
                })}
              </p>
              <h2 className="c-h3">{heading}</h2>
            </div>
            <div
              className="flex flex-col items-center text-center"
              data-sal="slide-down"
              data-sal-duration="600"
              data-sal-delay="200"
              data-sal-easing="ease"
            >
              <Arrow className="my-4 transform rotate-90" />
            </div>
          </div>
          <div
            className={cx(
              "relative h-[100px] bg-transparent w-full flex flex-col justify-center transition-colors duration-300 text-center",
              {
                "bg-black": pinIsActive,
              }
            )}
          >
            <p className="text-white c-h5">
              Scroll down to complete this Spark
            </p>
          </div>
          <div className="absolute bottom-0 left-0 w-full h-2 bg-white bg-opacity-10 blend-lighten">
            <div
              className="h-full bg-orange"
              style={{ width: !progCompleted ? progBar * 100 + "%" : "100%" }}
            ></div>
          </div>
        </div>
      </section>
    );
  }
);

CampaignHero.propTypes = {
  className: PropTypes.string,
};

export default CampaignHero;
